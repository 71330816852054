import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('user', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    userCheck(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`userCheck/${email}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('permissions')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('user', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveCompany(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCompany', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    updatePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/updatePassword/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    getCompany(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getCompany', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
